<template>
  <div>
    <h3>推广支出情况一览
      <el-link type='primary' icon='el-icon-refresh' @click='initData'></el-link>
    </h3>
    <div class='theme-border'>
      <div class='default-panel'>
        <div class='left-panel' v-loading='loading'>
          <div class='panel-row'>
            <span class='title'>年度：</span>
            <span>{{ year }} 年</span>
          </div>
          <div class='panel-row'>
            <span class='title'>总推广支出：</span>
            <span>{{ $utils.numberFormat(summaryData.cost_total, 2) }} 元</span>

            <div style='margin-left: 20px;margin-top: 20px'>
              <div class='panel-row'>
                <span class='title'>总涨粉投放：</span>
                <span>{{ $utils.numberFormat(summaryData.promotion_cost_total, 2) }} 元</span>
              </div>
              <div class='panel-row'>
                <span class='title'>总广告维护：</span>
                <span>{{ $utils.numberFormat(summaryData.ad_repair_total, 2) }} 元</span>
              </div>
              <div class='panel-row'>
                <span class='title'>总直播预热：</span>
                <span>{{ $utils.numberFormat(summaryData.live_preheat_total, 2) }} 元</span>
              </div>
              <div class='panel-row'>
                <span class='title'>总直播投放金：</span>
                <span>{{ $utils.numberFormat(summaryData.amount_live_total, 2) }} 元</span>
              </div>
            </div>
          </div>

          <div class='panel-row'>
            <el-checkbox v-model='checked'>对比去年</el-checkbox>
          </div>
        </div>
        <div class='right-panel'>
          <div class='right_panel_toolbar'>
            <PlatformsRadioGroup :platform-code='searchCondition.platform_code' :show-all='true'
                                 @choicePlatform='choicePlatform' />
          </div>
          <div>
            <echarts-chart :chart-option='chartOption' :width='`100%`' @chartSelect="selectKeyFun"/>

          </div>

        </div>
      </div>
      <div>
        <Tips :data='tipsList' />
      </div>
    </div>

  </div>
</template>

<script>
import EchartsChart from '@/components/EchartsChart'
import PlatformsRadioGroup from '@/pages/platform/components/PlatformsRadioGroup'

export default {
  name: 'DashboardPromotion',
  components: {
    EchartsChart, PlatformsRadioGroup
  },
  data() {
    return {
      loading: false,
      summaryData: {
        cost_total: 0,
        'promotion_cost_total': 0, 'ad_repair_total': 0, 'live_preheat_total': 0, 'amount_live_total': 0
      },
      chartDataMap: [
        { field: 'cost_total', title: '总推广支出', data_field: 'cost_arr' },
        { field: 'promotion_cost_total', title: '涨粉投放', data_field: 'promotion_cost_arr' },
        { field: 'ad_repair_total', title: '广告维护费', data_field: 'ad_repair_arr' },
        { field: 'live_preheat_total', title: '直播预热费', data_field: 'live_preheat_arr' },
        { field: 'amount_live_total', title: '总直播投放金', data_field: 'amount_live_arr' }
      ],
      chartOption: {},
      searchCondition: { year: null, platform_code: null },
      order_total_arr: [],
      revenue_total_arr: [],
      checked: false,
      yearRange: [],
      tipsList: [
        '总推广支出=总涨粉投放+总广告维护+总直插预热+总直播投放金总涨粉投放',
        '总广告维护、总直播预热，数据源来自“涨粉榜单”',
        '总直播投放金，数据源来自“直插数据”;'
      ],
      selectKey: {}
    }
  },
  props: {
    year: {
      type: String,
      required: true
    }
  },
  watch: {
    year() {
      this.searchCondition.year = this.year
      this.initData()
    },
    checked() {
      this.getChartData()
    }
  },
  mounted() {
    this.searchCondition.year = this.year
    this.searchCondition.platform_code = ''
    this.initData()
  },
  methods: {
    initData() {
      this.getStatistics()
      this.getChartData()
    },
    //年度数据统计
    async getStatistics() {
      this.loading = true
      this.order_total = 0
      this.goi_money = 0
      let searchCondition = this.handleCondition()
      this.summaryData = await this.$api.getStatisticsPromotion(searchCondition)
      // this.loading = false
      setTimeout(() => {
        this.loading = false
      }, 500)
    },
    //获取图表数据
    async getChartData() {
      let chartOption = {
        color: ['#ff3176'],
        title: {
          text: '推广支出情况一览 ',
          top: 0,
          left: '30%',
          textStyle: {
            color: '#303133',
            fontWeight: 'bold'
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },
        toolbox: {
          itemSize: '24',
          orient: 'vertical',
          feature: {
            dataView: { show: true, readOnly: true },
            magicType: { show: true, type: ['bar', 'line'] },
            restore: { show: true },
            saveAsImage: { show: true }
          }
        },
        legend: {
          data: [],
          left: '60%',
          selected: {}//默认显示的echarts图
        },
        xAxis: {
          type: 'category',
          data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
          axisPointer: {
            type: 'shadow'
          },
          axisLabel: {
            formatter: '{value}',
            show: true,
            color: '#ff3176'

          },
          nameTextStyle: {
            color: '#ff3176',
            padding: 10
          }
        },
        yAxis: {
          type: 'value',
          name: '金额（万元）',
          axisLabel: {
            formatter: '{value}',
            show: true,
            color: '#ff3176'

          },
          nameTextStyle: {
            color: '#ff3176',
            padding: 10
          }
        },
        series: []
      }

      let searchCondition = this.handleCondition()
      let barWidth = 0
      let barGap = '10%'
      // 修改柱状图 顶部数值 排版
      //https://echarts.apache.org/examples/zh/editor.html?c=bar-label-rotation
      // let labelOption = {
      //   show: true,
      //   rotate: 90,
      //   align: 'left',
      //   verticalAlign: 'middle',
      //   position: 'inside',
      //   distance: 15,
      //   // formatter: '{c}  {name|{a}}',
      //   fontSize: 16,
      //   rich: {
      //     name: {}
      //   }
      // }

      let chartData = await this.$api.getChartDataPromotion(searchCondition)
      this.chartSeriesAppend(chartOption, chartData, searchCondition.year, barWidth, barGap)

      if (this.checked) {
        searchCondition.year = searchCondition.year - 1
        let chartData = await this.$api.getChartDataPromotion(searchCondition)
        this.chartSeriesAppend(chartOption, chartData, searchCondition.year, barWidth, barGap)
      }

      this.initChartData(chartOption)


    },
    //图表-数据组装：追加
    chartSeriesAppend(chartOption, chartData, year, barWidth, barGap) {
      this.chartDataMap.forEach((item, key) => {
        chartOption.legend.data.push(`${year}-${item.title}`)
        if (JSON.stringify(this.selectKey) === '{}') {
          chartOption.legend.selected[`${year}-${item.title}`] = key === 0
        } else {
          chartOption.legend.selected = this.selectKey
        }
        //默认显示第一个维度的曲线
        chartOption.series.push({
          name: `${year}-${item.title}`,
          data: chartData[item.data_field] || [],
          type: 'bar',
          barWidth: 20,
          showBackground: false,
          backgroundStyle: {
            color: 'rgba(180, 180, 180, 0.2)'
          },
          barGap: barGap,
          //标签：顶部显示柱状图数值
          label: {
            show: true, //开启显示
            position: 'top', //在上方显示
            textStyle: { //数值样式
              color: '#ff3176',
              fontSize: 12
            }
          }
        })


      })
    },
    //渲染echarts
    initChartData(chartOption) {
      this.chartOption = chartOption
    },
    handleCondition() {
      let condition = {}
      if (this.searchCondition.year) {
        condition.year = this.searchCondition.year
      }
      if (this.searchCondition.platform_code) {
        condition.platform_code = this.searchCondition.platform_code
      }
      return condition
    },
    choicePlatform(val) {
      this.searchCondition.platform_code = val
      this.initData()
    },
    compareChart() {
      //对比去年
      this.getChartData()
    },
    selectKeyFun(data) {
      this.selectKey = data
    }
  }
}
</script>

<style scoped>
.default-panel {
  display: flex;
  width: 100%;
  height: auto;
  /*border: 1px solid rgba(255, 49, 118, 0.5);*/
}

.panel-row {
  margin-bottom: 20px;
}

.left-panel {
  flex: 3;
  /*border: 1px dashed #ff3176;*/
  padding: 10px 0 0 10px;
  margin: 10px 0 0 10px;
  /*border-right: 1px dashed rgba(255, 49, 118, 0.5);*/
}

.right-panel {
  flex: 9;
  padding: 5px;
  margin: 5px;
  /*border: 1px dashed #ff3176;*/
}

.title {
  font-size: 14px;
  font-weight: 700;
}

.digit-uppercase::before {
  content: '大写：';
}

.digit-uppercase {
  font-size: 0.8rem;
  font-weight: 600;
  letter-spacing: 5px;
  color: rgba(82, 82, 79, 0.8);
}
</style>
